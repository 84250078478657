<template>
  <section class="m-user-coupon m-scroll" ref="home" @scroll="handleScroll($event)">
    <!-- 头部导航 -->
    <m-header title="Gift cards & coupons">
      <span class="header-right" slot="right" @click="showFilters = true">Filters</span>
    </m-header>

    <!-- 兑换优惠券 -->
    <div class="exchange">
      <input type="text" placeholder="Enter the coupon code" v-model="code">
      <span @click="exchangeCouponHandle">Exchange</span>
    </div>

    <!-- 优惠券列表 -->
    <ul class="wrapper">
      <li class="item" v-for="(item, index) in data" :key="index">
        <div class="it-image">
          <img :src="item.coupon.pic_en.url" alt="">
        </div>
        <div class="it-tips" :class="{'it-tips-open': item.open }">
          <p>{{item.coupon.rule_en}}</p>
          <img @click="item.open = !item.open" ref="down" src="@/assets/public/icon-xiala.png" alt="">
        </div>
      </li>
      
      <van-loading v-show="loading" type="spinner" color="#ED2A24" size="6.4vw"></van-loading>
      <p class="search-no" v-if='noMore'>No More Coupon</p>
    </ul>

    <!-- 筛选弹窗 -->
    <van-popup v-model="showFilters" position="bottom" class="fliters-box">
        <m-header title="Fliters" @close="showFilters = false" :isBack="false">
            <span slot="right" class="top-right" @click="resetFilters">Clear</span>
        </m-header>
        <div class="filter-box">
          <div class="filter-title">Coupon Status</div>
          <div class="filter-item" v-for="(item, index) in orderType" :key="index" :class="{'filterActive' : typeActive == index}" @click="typeActive = index">
            <img src="@/assets/payment/select.png" alt="">
            <img src="@/assets/payment/active.png" alt="">
            <span>{{item}}</span>
          </div>
        </div>
        <div class="apply-set" @click="reGetCoupon">APPLY</div>
    </van-popup>
  </section>
</template>

<script>
import MHeader  from '@/components/en/m-header.vue'

import { getCouponList, exchangeCoupon } from '@/api/en/coupon.js'
export default {
  name:'Coupon',
  components:{ MHeader },
  data(){
    return {
      showFilters:false,   // 筛选面板显示
      month:1,        // 筛选的结果 
      orderType: ['All', 'Not Use', 'Used', 'Expire'],
      typeActive: 0,
      data: [],
      page: 1,
      code: '',
      getLock: false,
      islock: false,
      noMore: false,
      loading: false
    }
  },
  methods:{
    // 内容滑动事件
    handleScroll(e){
      e.preventDefault()
      e.stopPropagation()
      if(e.target.scrollHeight - e.target.clientHeight- e.target.scrollTop <= 50){
        if(!this.islock && !this.noMore){
          this.loading = true
          this.page++
          this.getCouponListHandle()
        }
      }
    },
    resetFilters() {
      // 重置筛选条件
      this.typeActive  = 0
    },
    getCouponListHandle() {
      // 获取优惠券列表
      this.islock = true
      getCouponList({page: this.page, is_ok: this.typeActive == 0 ? null : this.typeActive}).then(res => {
        if(res) {
          res.data.data.forEach(el => {
            el.open = false
          })
          this.data = this.data.concat(res.data.data)

          if(res.data.data.length < res.data.per_page) {
            this.noMore = true
          }
        } else {
          this.noMore = true
        }
      }).finally(() => {
        this.islock = false
        this.loading = false
      })
    },
    exchangeCouponHandle() {
      // 兑换优惠券
      let params = {}
      if(this.getLock || this.code == ''){ 
          return false
      }else {
        params.code = this.code.split('%%')
        if(params.code.length > 1) {
          params.code = params.code[1]
        } else {
          params.code = params.code[0]
        }
      }
      this.getLock = true
      exchangeCoupon(params).then(res => {
        if(res) {
           this.$notify({ type: 'success', message: res.msg })
          this.reGetCoupon()
        }
      }).finally(() => {
        this.getLock = false
      })
    },
    reGetCoupon() {
      // 根据筛选条件重新获取数据
      this.page = 1
      this.islock = false
      this.noMore = false
      this.data = []
      this.getCouponListHandle()
      this.showFilters = false
    }
  },
  created() {
    this.getCouponListHandle()
  }
}
</script>

<style lang="less" scoped>
.search-no{line-height:80px;text-align:center;color:#888;font-size:14px}
.top-right{color:#2A65BA !important;font-size:3.2vw;font-weight:600}
@import './index.less';
</style>